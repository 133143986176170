import React, { useCallback, useEffect, useState } from 'react';
import { AccountCard, ContentCurrentPlan } from './styles';
import { AccountStatus } from '../../types/AccountStatus';
import { Badge } from '../Badge';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import { HeaderScreen } from '../HeaderScreen';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { tipoCiclo, TipoCicloKeys } from '../../utils/tipo-ciclo';
import { differenceInDays, format, parseISO, startOfDay } from 'date-fns';
import { moneyFormat } from '~/utils/functions';
import {
  tipoFormaPagamento,
  TipoFormaPagamentoKeys,
} from '../../utils/tipo-forma-pagamento';
import { useStatusMap } from '../../hooks/usePlanStatus';
import { ScreenMeuPlano } from '../../types/context';

export const CurrentPlan: React.FC = () => {
  const {
    isDemonstracao,
    screen,
    contratacaoCliente,
    handleScreen,
    tipoStatus,
    isContratacaoCancelada,
    isPagamentoPendente,
    cicloFuturo,
    cicloFuturoFormaPagamento,
    contratacaoClienteFuturo,
    contratacaoPlanoFuturo,
    dtaDemonstracaoFim,
    desPlano,
    isDowngrade,
    contratacaoFuturoFormaPagamento,
  } = useMeuPlano();

  const [statusAcc, setStatusAcc] = useState<AccountStatus>('NENHUM');
  const [labelPlan, setLabelPlan] = useState('');
  const [dtaInicio, setDtaInicio] = useState('');

  const ciclo =
    tipoCiclo[Number(contratacaoCliente?.tipo_ciclo || 2) as TipoCicloKeys];
  const pagamento =
    tipoFormaPagamento[
      Number(
        cicloFuturoFormaPagamento?.tipo_forma_pagamento || 0,
      ) as TipoFormaPagamentoKeys
    ];
  const pagamentoDowngrade =
    tipoFormaPagamento[
      Number(
        contratacaoFuturoFormaPagamento?.tipo_forma_pagamento || 0,
      ) as TipoFormaPagamentoKeys
    ];
  const creditCardBrand =
    cicloFuturoFormaPagamento?.des_dados_json?.creditCard?.creditCardBrand;
  const creditCardBrandDowngrade =
    contratacaoFuturoFormaPagamento?.des_dados_json?.creditCard
      ?.creditCardBrand;
  const creditCarLastNumbers =
    cicloFuturoFormaPagamento?.des_dados_json?.creditCard?.lastFourDigits;
  const creditCarLastNumbersDowngrade =
    contratacaoFuturoFormaPagamento?.des_dados_json?.creditCard?.lastFourDigits;
  const statusMap = useStatusMap(labelPlan, dtaInicio);
  const { icon: Icon, label, iconColor, link } = statusMap[statusAcc];

  const handleClick = useCallback(
    (screenOpen: ScreenMeuPlano) => {
      if (screenOpen === 'PLANOS-DISPONIVEIS') {
        handleScreen(screenOpen);
      }

      if (
        screenOpen === 'MUDAR-FORMA-PAGAMENTO' &&
        isDemonstracao &&
        tipoStatus.tipoStatus === 2
      ) {
        handleScreen(screenOpen);
      }

      if (isDemonstracao) {
        return;
      }

      handleScreen(screenOpen);
    },
    [handleScreen, isDemonstracao, tipoStatus.tipoStatus],
  );

  useEffect(() => {
    if (isDemonstracao) {
      //* Está em processo de efetivação
      if (tipoStatus.contratacaoTransitoria) {
        setLabelPlan(contratacaoPlanoFuturo?.des_nome || '');
        setStatusAcc('AGUARDANDO-CONFIRMACAO-PAGAMENTO');
        return;
      }
      //* Não solicitou efetivação
      if (tipoStatus.contratacaoAtiva) {
        setStatusAcc('TRIAL');
        return;
      }
      return;
    }

    if (tipoStatus.contratacaoTransitoria && contratacaoClienteFuturo) {
      setLabelPlan(contratacaoPlanoFuturo?.des_nome || '');
      if (contratacaoClienteFuturo.tipo_contratacao === 2) {
        setStatusAcc('AGUARDANDO-CONFIRMACAO-PAGAMENTO');
      } else if (contratacaoClienteFuturo.tipo_contratacao === 3) {
        setDtaInicio(contratacaoClienteFuturo.dta_inicio || '');
        setStatusAcc('ENTRARA-EM-VIGOR');
      }
      return;
    }

    if (isContratacaoCancelada) {
      setStatusAcc('CONTINUAR-USANDO-SISTEMA');
      return;
    }

    if (tipoStatus.contratacaoAtiva) {
      if (isPagamentoPendente) {
        setStatusAcc('FALHA-PROCESSAMENTO-PAGAMENTO');
        return;
      }
      setStatusAcc('NENHUM');
      return;
    }

    setStatusAcc('NENHUM');
  }, [
    contratacaoClienteFuturo,
    contratacaoPlanoFuturo,
    isContratacaoCancelada,
    isDemonstracao,
    isPagamentoPendente,
    tipoStatus,
  ]);

  return (
    <ContentCurrentPlan>
      {screen === 'HOME' && (
        <>
          <div>
            <Badge
              label="Seu plano atual"
              backgroundColor="#57069e"
              size="small"
            />
          </div>
          <div className="plano-atual">
            <h3>{desPlano ?? 'NÃO IDENTIFICADO'}</h3>
            {isDemonstracao && (
              <Badge
                label="FASE DE TESTE"
                backgroundColor="#b3b3b3"
                size="small"
                isGradient
              />
            )}
            {!isDemonstracao && (
              <Badge
                label={ciclo}
                backgroundColor="#b3b3b3"
                size="small"
                isGradient
              />
            )}
          </div>
          {!isContratacaoCancelada && (
            <>
              {isDemonstracao && (
                <span>
                  <p>
                    Seu teste termina em{' '}
                    {dtaDemonstracaoFim &&
                      format(
                        startOfDay(new Date(dtaDemonstracaoFim)),
                        'dd/MM/yyyy',
                      )}{' '}
                    (
                    {dtaDemonstracaoFim &&
                      differenceInDays(
                        startOfDay(new Date(dtaDemonstracaoFim)),
                        startOfDay(new Date()),
                      )}{' '}
                    dias restantes)
                  </p>
                </span>
              )}
              {isDowngrade && (
                <>
                  <span>
                    Sua próxima cobrança será no dia{' '}
                    {format(
                      new Date(
                        contratacaoClienteFuturo?.dta_inicio || new Date(),
                      ),
                      'dd/MM/yyyy',
                    )}
                    , no valor de R${' '}
                    {moneyFormat(
                      String(contratacaoClienteFuturo?.val_plano || 0),
                    )}
                    .
                  </span>
                  {pagamentoDowngrade === 'Cartão de Crédito' && (
                    <span>
                      {pagamentoDowngrade} {creditCardBrandDowngrade} que
                      termina com {creditCarLastNumbersDowngrade}
                    </span>
                  )}
                  {pagamentoDowngrade !== 'Cartão de Crédito' && (
                    <span>{pagamentoDowngrade}</span>
                  )}
                </>
              )}
              {!isDemonstracao && !isDowngrade && (
                <>
                  <span>
                    Sua próxima cobrança será no dia{' '}
                    {format(
                      new Date(cicloFuturo?.dta_vencimento || new Date()),
                      'dd/MM/yyyy',
                    )}
                    , no valor de R${' '}
                    {moneyFormat(String(cicloFuturo?.val_plano || 0))}.
                  </span>
                  {pagamento === 'Cartão de Crédito' && (
                    <span>
                      {pagamento} {creditCardBrand} que termina com{' '}
                      {creditCarLastNumbers}
                    </span>
                  )}
                  {pagamento !== 'Cartão de Crédito' && (
                    <span>{pagamento}</span>
                  )}
                </>
              )}
            </>
          )}
          {isContratacaoCancelada && (
            <>
              <span>
                Seu acesso termina{' '}
                {format(
                  new Date(contratacaoCliente?.dta_fim || new Date()),
                  'dd/MM/yyyy',
                )}{' '}
                (
                {contratacaoCliente?.dta_fim
                  ? `${differenceInDays(
                      parseISO(contratacaoCliente.dta_fim),
                      new Date(),
                    )} dias restantes`
                  : 'Data de término não disponível'}
                )
              </span>
              <span style={{ color: 'red' }}>
                Solicitado o cancelamento do plano.
              </span>
            </>
          )}
          {statusAcc !== 'NENHUM' && (
            <AccountCard status={statusAcc} borderColor={iconColor}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <Icon size={24} color={iconColor} />
                <p>{label}</p>
                {link && <a href={link}>Clique aqui</a>}
              </div>
            </AccountCard>
          )}
        </>
      )}
      {screen === 'GERENCIAR-ASSINATURA' && (
        <div className="screen-plano-atual">
          <HeaderScreen showButtonVoltar />
          <div className="info-plano">
            <div className="badges">
              <Badge
                label="Seu plano atual"
                backgroundColor="#57069e"
                size="small"
                isGradient
              />
              <div>
                <h2>{desPlano}</h2>
                {isDemonstracao && (
                  <Badge
                    label="FASE DE TESTE"
                    backgroundColor="#b3b3b3"
                    size="small"
                    isGradient
                  />
                )}
                {!isDemonstracao && (
                  <Badge
                    label={ciclo}
                    backgroundColor="#b3b3b3"
                    size="small"
                    isGradient
                  />
                )}
              </div>
            </div>
            <hr />
            <div className="info-card">
              {!isDemonstracao && (
                <>
                  <span>
                    Sua próxima cobrança será no dia{' '}
                    {format(
                      new Date(cicloFuturo?.dta_vencimento || new Date()),
                      'dd/MM/yyyy',
                    )}
                    , no valor de R${' '}
                    {moneyFormat(String(cicloFuturo?.val_plano || 0))}.
                  </span>
                  {pagamento === 'Cartão de Crédito' && (
                    <span>
                      {pagamento} {creditCardBrand} que termina com{' '}
                      {creditCarLastNumbers}
                    </span>
                  )}
                  {pagamento !== 'Cartão de Crédito' && (
                    <span>{pagamento}</span>
                  )}
                </>
              )}
              {isDemonstracao && (
                <AccountCard status={statusAcc} borderColor={iconColor}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Icon size={24} color={iconColor} />
                    <p>{label}</p>
                    {link && <a href={link}>Clique aqui</a>}
                  </div>
                </AccountCard>
              )}
            </div>
          </div>
          <div className="buttons">
            <CustomButtonNovo
              label="Dados de cobrança"
              style={{
                backgroundColor: isDemonstracao ? '#9c9c9c' : '#007BFF',
              }}
              width="10rem"
              outline
              onClick={() => handleClick('MUDAR-DADOS-COBRANCA')}
            />
            <CustomButtonNovo
              label="Mudar de plano"
              style={{
                backgroundColor: '#FFC107',
              }}
              width="10rem"
              outline
              onClick={() => handleClick('PLANOS-DISPONIVEIS')}
            />
            <CustomButtonNovo
              label="Forma de pagamento"
              style={{
                backgroundColor:
                  isDemonstracao && tipoStatus.tipoStatus !== 2
                    ? '#9c9c9c'
                    : '#6F42C1',
              }}
              width="10rem"
              outline
              onClick={() => handleClick('MUDAR-FORMA-PAGAMENTO')}
            />
            <CustomButtonNovo
              label="Cancelar assinatura"
              style={{
                backgroundColor: isDemonstracao ? '#9c9c9c' : '#BF5050',
              }}
              width="10rem"
              outline
              onClick={() => handleClick('CANCELAR-ASSINATURA')}
            />
          </div>
        </div>
      )}
    </ContentCurrentPlan>
  );
};
