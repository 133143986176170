import React from 'react';
import { useMeuPlano } from '~/pages/MeuPlano/hooks/useMeuPlano';
import { TagLabel } from '../Tag';
import { DadosConcluirContainer } from './styles';
import { MdContentCopy } from 'react-icons/md';
import { MethodFaturamento } from '../../types/context';
import { FaRegCreditCard } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { moneyFormat } from '~/utils/functions';

const stepsBoleto = [
  {
    step: 1,
    label:
      'Você pode pagar seu boleto em qualquer lotérica, caixa eletrônico ou aplicatico de banco.',
  },
  {
    step: 2,
    label:
      'O seu pagamento será processado e confirmado em até 24h úteis. Portanto, após o pagamento aguarde a compensação para evitar duplicidade.',
  },
  {
    step: 3,
    label:
      'Se o vencimento do seu boleto cair em um feriado ou final de semana, você poderá pagá-lo no próximo dia útil.',
  },
];

export const DadosConcluir: React.FC<{
  faturamento?: MethodFaturamento;
  codBarrasBoleto?: string;
  urlBoleto?: string;
}> = ({ faturamento = undefined, codBarrasBoleto, urlBoleto }) => {
  const {
    methodFaturamento,
    formCard,
    chaveBoleto,
    valSelectedPlan,
    creditCard,
    boletoURL,
  } = useMeuPlano();

  const creditCardDigits = () => {
    const number = formCard.getValuesCard('number');

    if (number) {
      return number.substring(number.length - 4, number.length);
    }

    return '';
  };

  function downloadBoleto(url: string) {
    window.open(url, '_blank')?.focus();
  }

  function copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Código do boleto copiado para a área de transferência!');
      })
      .catch((err) => {
        toast.error('Erro ao copiar código do boleto: ', err);
      });
  }

  function getExpiryDate(year: string, month: string) {
    const yearEnding = year.substring(2, year.length);
    return `${month}/${yearEnding}`;
  }

  return (
    <DadosConcluirContainer>
      <TagLabel label="Esse sistema de pagamento está em ambiente seguro" />

      {(methodFaturamento === 'boleto' || faturamento === 'boleto') && (
        <div className="content-boleto">
          <span>Pagar com Boleto bancário</span>
          <div>
            <strong>
              Para pagar seu boleto, basta utilizar as informações abaixo:
            </strong>
            <div className="copiar-codigo">
              <div className="codigo-boleto">
                <span>Código</span>
                <span>{codBarrasBoleto || chaveBoleto}</span>
              </div>
              <MdContentCopy
                color="#69ACDD"
                size={20}
                onClick={() => {
                  copyToClipboard(codBarrasBoleto || chaveBoleto);
                }}
              />
            </div>
            <div className="info-uteis">
              {stepsBoleto.map((step) => (
                <div key={step.step} className="info">
                  <span>{step.step}</span>
                  <p>{step.label}</p>
                </div>
              ))}
            </div>
            <div className="buttons-boleto">
              {/* <button
                type="button"

              >
                Salvar boleto em PDF
              </button> */}
              <button
                type="button"
                className="print-boleto"
                onClick={() => {
                  if (urlBoleto || boletoURL)
                    downloadBoleto(urlBoleto || boletoURL);
                }}
              >
                Visualizar Boleto
              </button>
            </div>
          </div>
        </div>
      )}
      {(methodFaturamento === 'last-credit-card' ||
        faturamento === 'last-credit-card') && (
        <div className="content-boleto">
          <span>
            **** **** ****{' '}
            {creditCard.des_dados_json.creditCard?.lastFourDigits || ''}{' '}
            <FaRegCreditCard />
          </span>
          <div>
            <strong>
              Vence em{' '}
              {getExpiryDate(
                String(creditCard.des_dados_json.creditCard?.expiryYear),
                String(creditCard.des_dados_json.creditCard?.expiryMonth),
              )}
            </strong>
            <div className="copiar-codigo">
              <div className="codigo-boleto">
                <span>Valor a ser creditado</span>
                <span>R$ {moneyFormat(String(valSelectedPlan || 0))}.</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {(methodFaturamento === 'cartao-credito' ||
        faturamento === 'cartao-credito') && (
        <div className="content-boleto">
          <span>
            **** **** **** {creditCardDigits()} <FaRegCreditCard />
          </span>
          <div>
            <strong>Vence em {formCard.getValuesCard('expiry')}</strong>
            <div className="copiar-codigo">
              <div className="codigo-boleto">
                <span>Valor a ser creditado</span>
                <span>R$ {moneyFormat(String(valSelectedPlan || 0))}.</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </DadosConcluirContainer>
  );
};
