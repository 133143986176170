/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DadosClienteContainer } from './styles';

import {
  InputAsyncSelect,
  InputCpfCnpj,
  InputText,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { TagLabel } from '../Tag';
import { formatUtils } from '../../utils/format-utils';
import { useMeuPlano } from '../../hooks/useMeuPlano';

export const DadosCliente: React.FC<{
  plano?: string;
  tipo?: 'MENSAL' | 'ANUAL';
  insideAccordion?: boolean;
}> = ({ plano, tipo, insideAccordion = false }) => {
  const { formClienteContratacao } = useMeuPlano();
  const {
    controlClienteContratacao,
    formStateClienteContratacao: { errors },
    registerClienteContratacao,
    setValueClienteContratacao,
    clearErrorsClienteContratacao,
  } = formClienteContratacao;
  const [isFocused, setIsFocused] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [distance, setDistance] = useState<number | null>(null);

  const scrollToEnd = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsFocused(false);
    }
  };

  const handleInputClick = useCallback(() => {
    scrollToEnd();
  }, []);

  // const handlePageScrollLock = useCallback((lock: boolean) => {
  //   handleInputClick();
  //   document.body.style.overflow = lock ? 'hidden' : 'auto';
  // }, []);

  const handleFormat = (event: any) => {
    const { value, name } = event.target;

    if (name === 'num_cep') {
      event.target.value = formatUtils.formatCEP(value);
      setValueClienteContratacao(name, formatUtils.formatCEP(value));
    } else if (name === 'num_telefone') {
      event.target.value = formatUtils.formatCellphone(value);
      setValueClienteContratacao(name, formatUtils.formatCellphone(value));
    }
  };

  // useEffect(() => {
  //   if (!insideAccordion) return;

  //   handlePageScrollLock(isFocused);

  //   if (isFocused) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [insideAccordion, isFocused]);

  // useEffect(() => {
  //   const calculateDistance = () => {
  //     if (wrapperRef.current) {
  //       const element = wrapperRef.current;
  //       const elementBottom =
  //         element.getBoundingClientRect().bottom + window.scrollY;
  //       const bodyHeight = document.body.scrollHeight;
  //       const distanceToBody = bodyHeight - elementBottom;

  //       setDistance(distanceToBody);
  //     }
  //   };

  //   // Calcular a distância inicial
  //   calculateDistance();

  //   // Atualizar a distância ao redimensionar a janela ou rolar a página
  //   window.addEventListener('resize', calculateDistance);
  //   window.addEventListener('scroll', calculateDistance);

  //   return () => {
  //     window.removeEventListener('resize', calculateDistance);
  //     window.removeEventListener('scroll', calculateDistance);
  //   };
  // }, []);

  return (
    <DadosClienteContainer insideAccordion={insideAccordion}>
      {plano && tipo && (
        <TagLabel label={`Plano selecionado: ${plano} ${tipo}`} />
      )}
      <div className="form-dados">
        <div>
          <InputText
            maxLength={100}
            label="Nome completo"
            caseInput="upper"
            placeholder="Informe seu nome completo"
            name="des_nome"
            control={controlClienteContratacao}
            toLowerCase={false}
            register={registerClienteContratacao}
            isError={!!errors.des_nome}
          />
          <InputText
            maxLength={200}
            label="E-mail"
            placeholder="Informe seu e-mail"
            name="des_email"
            control={controlClienteContratacao}
            toLowerCase
            register={registerClienteContratacao}
            isError={!!errors.des_email}
            type="email"
          />
        </div>
        <div className="content-divider">
          <InputCpfCnpj
            label="CPF/CNPJ"
            name="num_cpf_cnpj"
            control={controlClienteContratacao}
            register={registerClienteContratacao}
            setValue={setValueClienteContratacao}
            isError={!!errors.num_cpf_cnpj}
            isDisabled={false}
          />
          <InputText
            maxLength={50}
            label="Celular"
            placeholder="(00) 00000-000"
            name="num_telefone"
            control={controlClienteContratacao}
            register={registerClienteContratacao}
            isError={!!errors.num_telefone}
            onInput={(e) => handleFormat(e)}
          />
        </div>
        <Separator labelText="Endereço de cobrança" marginTop="10px" />
        <div className="content-divider">
          <InputText
            maxLength={20}
            label="CEP"
            placeholder="00000-000"
            name="num_cep"
            control={controlClienteContratacao}
            register={registerClienteContratacao}
            isError={!!errors.num_cep}
            onInput={(e) => handleFormat(e)}
          />
          <InputText
            maxLength={150}
            label="Rua"
            placeholder="Informe a rua"
            name="des_rua"
            control={controlClienteContratacao}
            register={registerClienteContratacao}
            isError={!!errors.des_rua}
          />
        </div>
        <div className="content-divider">
          <InputText
            maxLength={10}
            label="Número"
            placeholder="Informe o número do endereço"
            name="des_numero"
            control={controlClienteContratacao}
            register={registerClienteContratacao}
            isError={!!errors.des_numero}
          />
          <InputText
            maxLength={150}
            label="Complemento"
            placeholder="Informe o complemento"
            name="des_complemento"
            control={controlClienteContratacao}
            register={registerClienteContratacao}
            isError={!!errors.des_complemento}
          />
        </div>
        <div
          ref={wrapperRef}
          className="content-divider"
          onClick={() => setIsFocused(true)}
        >
          <InputText
            maxLength={100}
            label="Bairro"
            placeholder="Informe o bairro"
            name="des_bairro"
            control={controlClienteContratacao}
            register={registerClienteContratacao}
            isError={!!errors.des_bairro}
          />
          <InputAsyncSelect
            label="Cidade"
            maxLength={100}
            placeholder="Selecione a cidade"
            name="des_cidade"
            register={registerClienteContratacao}
            isError={!!errors.des_cidade}
            control={controlClienteContratacao}
            listWidth="300px"
            changeSelected={(selected: any) => {
              const cidade = {
                value: selected.cod_cidade,
                ufValue: selected.des_sigla,
                label: selected.label,
                cidade: selected.des_cidade,
              };

              clearErrorsClienteContratacao('des_cidade');
              setValueClienteContratacao('des_cidade', cidade);
            }}
            api={{
              route: 'busca-cidades',
              method: 'get',
              fields: ['des_cidade', 'des_sigla'],
              searchBeforeFilter: true,
            }}
          />
        </div>
      </div>
    </DadosClienteContainer>
  );
};
